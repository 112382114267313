import 'react-phone-input-2/lib/style.css';
import './css/custom.css';
import "./css/react-big-calendar.css";

import * as BackendApi from '../BackendApi';

import { Calendar, momentLocalizer } from './Calendar';
import React, { Component } from 'react';
import {
  closeLoading,
  errorTips,
  getCurrentCountry,
  infoTips,
  openLoading,
  openMessageDialog,
  successTips,
  warningTips
} from '../../utils';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Alert from '@mui/material/Alert';
import AppBar from '@mui/material/AppBar';
import AppDetailDialog from './AppDetailDialog';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { CalendarAreaContainer } from './CalendarArea.Styles';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import CloseIcon from '@mui/icons-material/Close';
import CreateAppDialog from './CreateAppDialog';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import LoadingButton from '@mui/lab/LoadingButton';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import PhoneInput from 'react-phone-input-2';
import PropTypes from 'prop-types';
import RetentionTimeDialog from './RetentionTimeDialog';
import { Search } from '@mui/icons-material';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from "@mui/material/TableFooter";
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Tabs from '@mui/material/Tabs';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import moment from '../../i18n/moment';
import { openConfirmDialog } from '../../utils';

const localizer = momentLocalizer(moment)
class CalendarArea extends Component {
  static propTypes = {
    calendarEvents: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedEvent: PropTypes.object,
    authUser: PropTypes.object,
    offers: PropTypes.arrayOf(PropTypes.object).isRequired,
    jumpToDate: PropTypes.func,
    selectEvent: PropTypes.func,
    highlightEvent: PropTypes.func,
    selectedContact: PropTypes.object,
    email_reg: PropTypes.object,
  }

  state = {
    // timezone: false,
    isOpenCreateAppDialog: false,
    isOpenAppDetailDialog: false,
    isOpenEditAppDialog: false,
    isOpenappointDrawer: false,
    inMovingEvent: null,

    // search store list
    optician_search: '',
    opticianSearchDisabled: false,
    optician_zip_search: '',
    zipSearchDisabled: false,
    filterLastMinute: false,

    highlightEventId: "",

    storeMap: {},
    storeInMap: '',
    emptyLastMinuteStore: false,

    // select store
    selectedStore: null,

    // switch calendar
    selectedCalendar: false,
    calendarDate: new Date(),
    calendarView: 'week',
    event_list: [],
    closed_block: [],
    app_open_close_list: [],

    // loading lastminute async
    loadingLastMinute: false,
    BusinessHoursList: null,

    // available dates
    availableDateMap: {},
    dateInAvailableDateMap: '',
    availableTimeMap: {},
    timeInAvailableTimeMap: '',
    selectedAvailableTimeBlock: null,

    checkedBlock: null,

    prejumpdate: new Date(),

    operation_app_id: '',
    event_name: '',
    event_offer: '',
    event_offervoucher: '',
    event_offerdescription: '',
    event_description: '',
    event_not_buy_reason: '',
    event_phone: '',
    event_mobile: '',
    event_email: '',
    event_firstname: '',
    event_lastname: '',
    event_eye_test: false,
    event_status: '',
    event_app_time: '',
    event_source: '',
    event_start_date: '',
    event_end_date: '',
    event_start_time: '',
    event_end_time: '',
    event_mo: false,
    event_tu: false,
    event_we: false,
    event_th: false,
    event_fr: false,
    event_sa: false,
    event_su: false,
    event_double_confirm_required:false,
    event_double_confirm:false,
    event_is_new: false,
    event_remark: '',
    app_time: '',
    zipcode: '',
    create_app_time: '',
    appointmentLog: [],

    keyup_condition: '',
    appointmentSearchDisabled: false,
    keyup_result: [],
    select_keyup_id: '',

    tabs_value: 0,
    appointmentRows: [],
    newPageAppointmentRows: [],
    rows_perpage_options: 10,
    rowsPerPage: 0,
    startRowsNumber: 0,
    endRowsNumber: 10,
    appointmentRowsCount: 0,
    listDelAppointment: false,
    listDelAppointmentId: '',
    listDelAppointmentDep: '',
    delDescriptionErrorTips: '',
    emailGdprErrorTips: '',
    customerRefGdprErrorTips: '',
    orderNumberGdprErrorTips: '',
    tabTwoEventId: '',
    tabTwoCondition: '',
    tabTwoDelStatus: false,
    gdprEmail: '',
    gdprphone: '',
    gdprCustomerRef: '',
    gdprOrderId: '',
    isOpenPhoneGdprButton: true,
    isOpenEmailGdprButton: true,
    gdpr: {
      unsubscribeFromNewsletter: false,
      addToDoNotCallList: false,
      deleteCustomerDetails: false,
      unsubscribeFromAll: false,
      unsubscribeFromLetter: false,
      unsubscribeFromSms: false,
      unsubscribe_points_news:false,
      unsubscribe_whatsapp:false
    },

    isOpenRetentionTimeDialog: false,
    isRefresh:false
  }

  componentDidMount() {
    const { selectedContact, authUser, leadApp } = this.props
    let condition = ''
    let filterLastMinute = false
    if (selectedContact) {
      if (selectedContact.phone || selectedContact.mobile) {
        condition = selectedContact.phone ? selectedContact.phone : selectedContact.mobile
      }
      else if (selectedContact.email) {
        condition = selectedContact.email
      } else if (selectedContact.first_name || selectedContact.last_name) {
        let first = selectedContact.first_name
        let last = selectedContact.last_name
        if (first && last) {
          condition = first + ' ' + last
        } else if (first) {
          condition = first
        } else if (last) {
          condition = last
        }
      }
      if (selectedContact.campaign_code?.indexOf("-lm") >= 0 || selectedContact.campaign_code?.indexOf("lastminute") >= 0) {
        filterLastMinute = true
      }
    }

    let opticianSearch = leadApp?.store_ref || ""
    let opticianZipSearch = selectedContact?.zip || ''

    this.setState({
      optician_search: opticianSearch,
      optician_zip_search: opticianZipSearch,
      filterLastMinute: filterLastMinute,

      tabTwoCondition: condition,
      gdprEmail: selectedContact ? selectedContact.email : '',
      gdprphone: selectedContact ? (selectedContact.phone ? selectedContact.phone : selectedContact.mobile) : '',
      gdprCustomerRef: selectedContact ? selectedContact.customer_no : '',

      highlightEventId: leadApp?.event_id || '',
    })
    let searchAppointmentDate = leadApp?.app_time ? moment(leadApp.app_time).toDate() : false
    this.searchStoreAction(opticianSearch, opticianZipSearch, filterLastMinute, searchAppointmentDate)
    console.log('Calendar View componentDidMount, authUser: ', authUser, selectedContact)
  }

  searchStore = async (opticianSearch = false, opticianZipSearch = false, filterLastMinute = false, defaultDate = false) => {
    const { t } = this.props
    let storeMap = {}
    let lastMinuteStoreCount = 0
    try {
      let storeList = await BackendApi.search_store_list(opticianSearch, opticianZipSearch).then(response => response.data)
      storeList?.filter(s => s.ref).forEach(store => {
        let supportLastMinute = false
        if (store.ability?.hasOwnProperty("Last Minute") || store.ability?.hasOwnProperty("LM")) {
          supportLastMinute = true
        }
        let calendarModels = store?.calendar_model ? JSON.parse(store.calendar_model) : []
        let wrappedStore = { ...store, supportLastMinute: supportLastMinute, calendarModels: calendarModels }

        if (opticianSearch && wrappedStore.ref === opticianSearch) {
          storeMap[wrappedStore.ref] = wrappedStore
        }
        if (filterLastMinute) {
          if (wrappedStore.supportLastMinute) {
            storeMap[wrappedStore.ref] = wrappedStore
            lastMinuteStoreCount = lastMinuteStoreCount + 1
          }
        } else {
          storeMap[wrappedStore.ref] = wrappedStore
        }
      })
    } catch (error) {
      console.error("Search store failed.", error)
      errorTips(t('FlexCalendar:Went wrong when searching the store, please try again later'))
    }

    let storeInMap = ''
    if (opticianSearch && storeMap[opticianSearch]) {
      if (this.state.selectedStore?.ref === opticianSearch) {
        await this.showStore(storeMap[opticianSearch], defaultDate || this.state.calendarDate, this.state.calendarView, this.state.selectedCalendar)
      } else {
        await this.showStore(storeMap[opticianSearch], defaultDate)
      }
      storeInMap = opticianSearch
    } else if (this.state.selectedStore && storeMap[this.state.selectedStore.ref]) {
      await this.showStore(storeMap[this.state.selectedStore.ref], defaultDate || this.state.calendarDate, this.state.calendarView, this.state.selectedCalendar)
      storeInMap = this.state.selectedStore.ref
    }
    let emptyLastMinuteStore = filterLastMinute && lastMinuteStoreCount === 0 ? true : false
    this.setState({ storeMap: storeMap, storeInMap: storeInMap, emptyLastMinuteStore: emptyLastMinuteStore })
  }

  searchStoreAction = (opticianSearch = false, opticianZipSearch = false, filterLastMinute = false, defaultDate = false) => {
    openLoading()
    this.setState({ opticianSearchDisabled: true, zipSearchDisabled: true }, () => {
      this.searchStore(opticianSearch, opticianZipSearch, filterLastMinute, defaultDate)
        .finally(() => {
          closeLoading()
          this.setState({ opticianSearchDisabled: false, zipSearchDisabled: false })
        })
    })

  }

  showStore = async (store, showDate = false, showView = false, showCalendarModel = false) => {
    if (!store) {
      this.setState({
        selectedStore: null,

        selectedCalendar: false,
        calendarDate: new Date(),
        calendarView: 'week',

        event_list: [],
        closed_block: [],
        app_open_close_list: [],
      })
      this.props.selectStore(null)
      return
    }

    console.info("Show store", store)

    let switchedCalendar = showCalendarModel === false ? (store.calendarModels?.length > 0 ? store.calendarModels[0] : false) : (store.calendarModels?.indexOf(showCalendarModel) >= 0 ? showCalendarModel : false)

    await this.switchCalendar(store, showDate, showView, switchedCalendar)

    let availableResult = await this.fetchAvailableDates(store.ref)

    this.setState({
      ...availableResult,
      selectedStore: { ...store },
    })
    this.props.selectStore({ ...store })

    // 异步加载
    store.supportLastMinute && this.fetchLastMinuteAds(store)
  }

  showStoreAction = (store, showDate = false, showView = false, showCalendarModel = false) => {
    openLoading()
    this.showStore(store, showDate, showView, showCalendarModel)
      .finally(() => {
        closeLoading()
      })
  }

  switchCalendar = async (store, showDate = false, showView = false, showCalendarModel = false) => {
    const { t } = this.props
    let calendarDate = showDate ? moment(showDate).toDate() : new Date()
    let calendarView = showView || 'week'

    let switchedCalendar
    if (showCalendarModel === false) {
      switchedCalendar = store.calendarModels?.length > 0 ? store.calendarModels[0] : false
    } else {
      switchedCalendar = showCalendarModel
    }
    console.log('siwtchCalendar', store, switchedCalendar, calendarView, calendarDate)
    let event_list = []
    let closed_block = []
    let app_open_close_list = []
    if (switchedCalendar) {
      let result = await this.fetchCalendarData(store?.ref, switchedCalendar, calendarDate, calendarView)
      event_list = result.event_list
      closed_block = result.closed_block
      app_open_close_list = result.app_open_close_list
    } else {
      errorTips(t('FlexCalendar:No calendar found, please check calendar settings in ERP backend'))
    }
    this.setState({
      selectedCalendar: switchedCalendar,

      calendarDate: calendarDate,
      calendarView: calendarView,

      event_list: event_list,
      closed_block: closed_block,
      app_open_close_list: app_open_close_list,
    })
  }

  switchCalendarAction = (store, showDate = false, showView = false, showCalendarModel = false) => {
    openLoading()
    this.switchCalendar(store, showDate, showView, showCalendarModel)
      .finally(() => {
        closeLoading()
      })
  }

  fetchCalendarData = async (storeRef, calendarModel, date, view) => {
    const { t } = this.props
    let event_list = []
    let closed_block = []
    let app_open_close_list = []
    let start_date, end_date, fetch_date
    fetch_date = moment(date).startOf('day')
    switch (view) {
      case 'day':
        start_date = fetch_date.clone()
        end_date = fetch_date.clone()
        break
      case 'week':
        start_date = fetch_date.clone().startOf('week')
        end_date = fetch_date.clone().endOf('week')
        break
      default:
        return { event_list: event_list, closed_block: closed_block, app_open_close_list: app_open_close_list }
    }
    console.log('Fetch store calendar data in date range', storeRef, calendarModel, view, start_date.format('YYYY-MM-DD'), end_date.format('YYYY-MM-DD'))
    try {
      let response = await BackendApi.app_openclose_list({
        store_id: storeRef,
        calendar_number: calendarModel,
        start_date: start_date.format('YYYY-MM-DD'),
        end_date: end_date.format('YYYY-MM-DD'),
      })
      if (response.success && response.data) {
        response.data.forEach(block => {
          if (block.title === "Optician appointment") {
            let _start = moment(block.start).toDate()
            let _status = block.operation_status || ""
            let _size = (_start && _start >= moment({ h: 0, m: 0, s: 0, ms: 0 }) && (_status === 'DONE' || (getCurrentCountry() === 'es' && _status === 'NOT YET'))) ? 0.7 : 1
            // appointment block
            event_list.push({
              // calendar fields
              title: block.name,
              start: _start,
              end: moment(block.end).toDate(),
              allDay: block.allDay,
              size: _size,

              // customer fields
              type: 'appointment',
              status: _status,
              id: block.id,

              source: { ...block }
            })
          } else {
            if (block.id === "0") {
              // closed block
              closed_block.push({
                // calendar fields
                title: block.title,
                start: moment(block.start).toDate(),
                end: moment(block.end).toDate(),
                allDay: block.allDay,

                // customer fields
                type: 'closed',
                status: "",
                id: "0",
              })
            } else {
              // blocked block
              closed_block.push({
                // calendar fields
                title: block.title,
                start: moment(block.start).toDate(),
                end: moment(block.end).toDate(),
                allDay: block.allDay,

                // customer fields
                type: 'blocked',
                status: "",
                id: block.id,

                source: { ...block }
              })
            }

          }
        });
        app_open_close_list = response.data;
        if (app_open_close_list.length > 0 && end_date.isSameOrAfter(moment(new Date()), 'day')) {
          let days = 1 + end_date.clone().diff(start_date, 'days')
          let search_date = start_date.format('YYYY-MM-DD')
          try {
            let day_list = await BackendApi.available_booking_date_2(storeRef, search_date, days, days)
            day_list?.filter(e => moment(e?.current_date).isSameOrBefore(end_date, 'day')).forEach(day_item => {
              day_item?.calendar?.filter(e => e && e.calendar_model === calendarModel).forEach(calendar_item => {
                calendar_item?.time_list?.forEach(time_item => {
                  let item_start = moment(`${day_item.current_date} ${time_item}`, 'YYYY-MM-DD HH:mm')
                  if (item_start.isSameOrAfter(moment(new Date()), 'day')) {
                    let item_end = item_start.clone()
                    item_end.add(calendar_item.date_length, 'minutes')
                    if (item_start.isValid() && item_end.isValid()) {
                      event_list.push({
                        // calendar fields
                        title: "",
                        start: item_start.toDate(),
                        end: item_end.toDate(),
                        allDay: false,

                        // customer fields
                        type: 'empty',
                        status: '',
                        id: 0,

                        interval: calendar_item.date_length,
                      })
                    }
                  }
                })
              })
            })
          } catch (error) {
            console.log('fetch available days error', storeRef, search_date, days, error)
          }
        }
      }
    } catch (error) {
      console.log('fetch open close time error', storeRef, calendarModel, error)
      errorTips(t('FlexCalendar:Went wrong when fetch the calendar data, please try again later'))
    }
    return { event_list: event_list, closed_block: closed_block, app_open_close_list: app_open_close_list }
  }

  fetchLastMinuteAds = async (store) => {
    let lastMinuteTimeList = null
    this.setState({ loadingLastMinute: true })
    try {
      let result = await BackendApi.search_store_open_time(store?.ref)
      if (result?.business_hours_list?.length > 0) {
        lastMinuteTimeList = result.business_hours_list
      }
    } catch (error) {
      console.log('fetch last minute ads failed.', error)
    }
    this.setState({
      BusinessHoursList: lastMinuteTimeList,
      loadingLastMinute: false,
    })
  }

  fetchAvailableDates = async (storeRef) => {
    let availableDateMap = {}
    let searchDate = moment(new Date())
    // searchDate.add(1, 'days')
    let maxSearchDays = 180
    try {
      let day_list = await BackendApi.available_booking_date_2(storeRef, searchDate.format('YYYY-MM-DD'), 15, maxSearchDays)
      day_list?.forEach(day_item => {
        let dayTimeMap = {}
        day_item?.calendar?.forEach((calendar_item, calendar_index) => {
          calendar_item?.time_list?.forEach((time_item, time_index) => {
            let item_start = moment(`${day_item.current_date} ${time_item}`, 'YYYY-MM-DD HH:mm')
            let item_end = item_start.clone()
            item_end.add(calendar_item.date_length, 'minutes')
            if (item_start.isValid() && item_end.isValid()) {
              dayTimeMap[`${calendar_item.calendar_model}_${time_index}`] = {
                // calendar fields
                title: calendar_index === 0 ? time_item : `${time_item} (${calendar_item.calendar_model})`,
                start: item_start.toDate(),
                end: item_end.toDate(),
                allDay: false,

                // customer fields
                type: 'available',
                status: '',
                id: 0,

                interval: calendar_item.date_length,

                calendarModel: calendar_item.calendar_model,
              }
            }
          })
        })
        if (Object.keys(dayTimeMap).length > 0) {
          availableDateMap[day_item.current_date] = dayTimeMap
        }
      })
    } catch (error) {
      console.log(`fetch available time after ${searchDate} in ${maxSearchDays} days error`, storeRef, error)
    }
    let defaultDate = ''
    let timeMap = {}
    let defaultTime = ''
    let selectedTimeBlock = null
    if (this.state.selectedStore?.ref === storeRef && availableDateMap[this.state.dateInAvailableDateMap]) {
      defaultDate = this.state.dateInAvailableDateMap
      timeMap = { ...availableDateMap[this.state.dateInAvailableDateMap] }
      if (timeMap[this.state.timeInAvailableTimeMap]) {
        defaultTime = this.state.timeInAvailableTimeMap
        selectedTimeBlock = { ...timeMap[defaultTime] }
      }
    }
    return {
      availableDateMap: availableDateMap,
      dateInAvailableDateMap: defaultDate,
      availableTimeMap: timeMap,
      timeInAvailableTimeMap: defaultTime,
      selectedAvailableTimeBlock: selectedTimeBlock,
    }
  }

  //Create appointment popup
  openCreateAppDialog = (block) => {
    const { selectedContact } = this.props
    if (selectedContact != null) {
      this.setState({
        create_app_time: moment(block.start),
        event_firstname: selectedContact.first_name,
        event_lastname: selectedContact.last_name,
        event_offer: selectedContact.campaign_code,
        event_phone: selectedContact.phone,
        event_mobile: selectedContact.mobile,
        event_email: selectedContact.email,
        zipcode: selectedContact.zip,
        event_description: '',

        checkedBlock: { ...block },
        isOpenCreateAppDialog: true
      })
    } else {
      this.setState({
        create_app_time: moment(block.start),
        event_firstname: '',
        event_lastname: '',
        event_offer: '',
        event_phone: '',
        event_mobile: '',
        event_email: '',
        zipcode: '',
        event_description: '',
        operation_status: '',
        event_app_time: '',

        checkedBlock: { ...block },
        isOpenCreateAppDialog: true
      })
    }
  }

  closeCreateAppDialog = () => {
    this.setState({ isOpenCreateAppDialog: false, checkedBlock: null })
  }

  //Appointment details
  openAppDetailDialog = (block) => {
    if (block.type === 'appointment' || block.type === 'blocked') {
      let event_info = block.source
      console.info("event_info",event_info)
      this.setState({
        operation_app_id: block.id,

        event_name: event_info.name,
        event_offer: event_info.offer,
        event_offervoucher: event_info.product_voucher,
        event_offerdescription: event_info.offer_description,
        event_description: event_info.description,
        event_firstname: event_info.first_name,
        event_lastname: event_info.last_name,
        event_phone: event_info.phone,
        event_mobile: event_info.mobile,
        event_email: event_info.email,
        app_time: moment(event_info.start).format('YYYY-MM-DD HH:mm:ss'),
        zipcode: event_info.zip,
        event_status: event_info.operation_status,
        event_app_time: event_info.start,
        event_eye_test: event_info.eye_test,
        event_start_date: event_info.start_date,
        event_end_date: event_info.end_date,
        event_start_time: event_info.start_time,
        event_end_time: event_info.end_time,
        event_mo: event_info.mo,
        event_tu: event_info.tu,
        event_we: event_info.we,
        event_th: event_info.th,
        event_fr: event_info.fr,
        event_sa: event_info.sa,
        event_su: event_info.su,
        event_is_new: event_info.is_new,
        event_double_confirm_required: event_info.double_confirm_required,
        event_double_confirm: event_info.double_confirm,
        event_remark: event_info.remark,
        event_source: event_info.agent ? (event_info.agent === 'lead' ? 'online' : event_info.agent) : '',

        checkedBlock: { ...block },
        isOpenAppDetailDialog: true
      })
    }
  }

  closeAppDetailDialog = () => {
    this.setState({ isOpenAppDetailDialog: false, checkedBlock: null })
  }
  //
  openEditAppDialog = () => {
    this.setState({ isOpenEditAppDialog: true })
  }
  //Appointment move start
  startMoveEvent = () => {
    const { t } = this.props
    if (this.state.checkedBlock?.type === 'appointment') {
      console.log("Moving: ", this.state.checkedBlock)
      this.setState({ inMovingEvent: { ...this.state.checkedBlock.source } })
      this.closeAppDetailDialog()
      infoTips(t('FlexCalendar:Start to Move an app.'))
    }
  }
  //Appointment move stop
  stopMoveEvent = () => {
    const { t } = this.props
    console.log("Stop Move")
    this.setState({ inMovingEvent: null })
    warningTips(t('FlexCalendar:Move opration is stopped!'))
  }
  //Appointment move operation

  moveIntoBlock = async (block) => {
    const { t, authUser } = this.props
    if (!this.state.selectedStore) {
      errorTips(t('FlexCalendar:Please select the store to move in'))
      return false;
    }
    let time_zone = moment.tz.guess()
    let operating_id = authUser.id
    let app_date = moment.utc(block.start).format('YYYY-MM-DD')
    let app_time = moment.utc(block.start).format('HH:mm:ss')
    let store_ref = this.state.selectedStore.ref
    let store_id = this.state.selectedStore.id
    let event_id = this.state.operation_app_id
    let lead_id = this.state.inMovingEvent.crm_lead_id
    let calendar_model = this.state.selectedCalendar
    let date_length = block.interval

    let move_app_date = {
      time_zone: time_zone, operating_id: operating_id,
      app_date: app_date, app_time: app_time, store_id: store_id, event_id: event_id,
      lead_id: lead_id, calendar_model: calendar_model, date_length: date_length,
      store_ref:store_ref
    }
    try {
      await BackendApi.move_appointment(move_app_date)
      successTips(t('FlexCalendar:Move Success!'))
    } catch (error) {
      console.error('moveIntoBlock failed', event_id, block, error)
      warningTips(t('FlexCalendar:Move failed, please move again!'))
      return false
    }

    return true
  }

  //Operation to create a new appointment
  CreateNewAppointment = async (event) => {
    const { t, selectedContact, authUser } = this.props
    let app_date = moment.utc(this.state.create_app_time).format('YYYY-MM-DD')
    let app_time = moment.utc(this.state.create_app_time).format('HH:mm:ss')
    console.info(app_date, app_time)
    let store_ref = this.state.selectedStore.ref
    let store_id = this.state.selectedStore.id
    let calendar_model = this.state.selectedCalendar
    let date_length = this.state.checkedBlock.interval
    openLoading()
    console.info("event", event)
    let create_app_date = {
      lead_id: selectedContact == null ? '' : selectedContact.lead_id,
      zipcode: event.zipcode, first_name: event.first_name, last_name: event.last_name,
      store_id: store_id, pathname: event.campaign_code, source_url: '',
      birthday: '', page_type: '', campaign_code: event.campaign_code, phone_prefix: event.phone_prefix,
      phone_number: event.phone_number, time_zone: moment.tz.guess(), app_date: app_date, app_time: app_time,
      nav_lang: authUser.lang, calendar_model: calendar_model, date_length: date_length,
      campaign_name: event.campaign_code, local_date: moment(this.state.create_app_time).format('YYYY-MM-DD'),
      local_time: moment(this.state.create_app_time).format('HH:mm'), operating_id: authUser.id,
      eye_test: event.eye_test, remark:event.remark, store_ref:store_ref
    }
    if (event.email) {
      create_app_date['email'] = event.email
    }
    try {
      let result = await BackendApi.create_appointment(create_app_date)
      console.log('Create App. Success. Result:', result)
      successTips(t('FlexCalendar:Create Success!'))
      this.closeCreateAppDialog()
      await this.switchCalendar(this.state.selectedStore, this.state.create_app_time, this.state.calendarView, this.state.selectedCalendar)
    } catch (error) {
      console.log('Create App. Failed.', error)
      if (error && error.error_message) {
        switch (error.error_message.code) {
          case 40102:
            warningTips(t('FlexCalendar:Create failed, this time slot has been selected by someone else a few seconds ago!'))
            return
          case 40103:
            warningTips(t('FlexCalendar:Create failed, customer already have an appointment!'))
            return
        }
      }
      warningTips(t('FlexCalendar:Create failed, please try again!'))
    } finally {
      closeLoading()
    }
  }

  //Calendar display
  onEventPropGetter = (event, start, end, isSelected) => {
    if (this.state.highlightEventId && this.state.highlightEventId === event.id) {
      return {
        style: {
          background: 'linear-gradient(to right, aqua 35%, #7c96df)',
        }
      };
    }
    if (event.type === "closed") {
      return {
        style: {
          background: 'indianred',
        }
      };
    } else if (event.type === "blocked") {
      return {
        style: {
          background: "crimson",
        }
      };
    } else if (event.type === "appointment") {
      let background_close = '#ffa500'
      if (event.status === 'NORMAL' || event.status === 'PENDING') {
        background_close = '#ffa500'
        if(event.platform){
          background_close = '#fcc86a'
        }
        if(event.source && event.source.app_source === 'AGENT'){
          background_close = '#750a4f'
          if(event.platform){
            background_close = '#cd0686'
          }
        }else if(event.source && event.source.app_source === 'OPTIKER'){
          background_close = '#FF4D00';
        }
      } else if (event.status === 'NOT BUYING' || event.status === 'NOT YET') {
        background_close = 'gray'
        if(event.source && event.source.app_source === 'AGENT'){
          background_close = '#9a6c8a'
        }
      } else if (event.status === 'DONE') {
        background_close = 'royalblue'
        if(event.source && event.source.app_source === 'AGENT'){
          background_close = '#6c8ae6'
        }
      }
      return {
        style: {
          background: background_close,
        }
      };
    } else if (event.type === "empty") {
      return {
        style: {
          background: 'green',
        }
      };
    }

    return {};
  }

  getNow = () => {
    let nowDate = new Date()
    if (getCurrentCountry() == 'us') {
      nowDate = moment.tz(new Date(), "America/Los_Angeles").format('YYYY-MM-DD HH:mm:ss')
    } else if (getCurrentCountry() == 'gb') {
      nowDate = moment.tz(new Date(), "Europe/London").format('YYYY-MM-DD HH:mm:ss')
    } else if (getCurrentCountry() == 'at') {
      nowDate = moment.tz(new Date(), "Europe/Vienna").format('YYYY-MM-DD HH:mm:ss')
    } else if (getCurrentCountry() == 'de') {
      nowDate = moment.tz(new Date(), "Europe/London").format('YYYY-MM-DD HH:mm:ss')
    } else if (getCurrentCountry() == 'pl') {
      nowDate = moment.tz(new Date(), "Europe/Warsaw").format('YYYY-MM-DD HH:mm:ss')
    } else if (getCurrentCountry() == 'es') {
      nowDate = moment.tz(new Date(), "Europe/Madrid").format('YYYY-MM-DD HH:mm:ss')
    } else if (getCurrentCountry() == 'it') {
      nowDate = moment.tz(new Date(), "Europe/Rome").format('YYYY-MM-DD HH:mm:ss')
    }
    return nowDate
  }

  //Click the appointment calendar
  onClickEvent = async (block) => {
    const { selectEvent, t } = this.props
    console.log('onClickEvent', block)
    if (block.type === 'appointment' || block.type === 'blocked') {
      if (this.state.inMovingEvent) {
        openMessageDialog(t('FlexCalendar:message.preventOpenEditWhenMovingApp'))
        return false
      }
      this.openAppDetailDialog(block)
      selectEvent(block)
    } else if (block.type === 'empty') {
      let nowDate = this.getNow()
      if (moment(block.start).isBefore(nowDate)) {
        openMessageDialog(t('FlexCalendar:message.notAllowBookingInThePast'))
      } else if (this.state.inMovingEvent) {
        openLoading(t('FlexCalendar:message.movingLoading'))
        let result = await this.moveIntoBlock(block)
        if (result) {
          this.setState({ inMovingEvent: null })
          await this.switchCalendar(this.state.selectedStore, block.start, this.state.calendarView, this.state.selectedCalendar)
        }
        closeLoading()
      } else {
        this.openCreateAppDialog(block)
      }
    }
    return false
  }

  onRefreshEvent = async () => {
    const { selectEvent, t } = this.props;
    this.setState({isRefresh:true})
    openLoading()
    BackendApi.search_app_event_info(this.state.operation_app_id).then(list => {
      if (list.success) {
        this.openAppDetailDialog(list.data)
        selectEvent(list.data)
      }
    }).catch(error => {
      errorTips(t('FlexCalendar:Get logs from backend failed, please try again later.'))
    }).finally(() => {
      closeLoading()
      setTimeout(()=>{
        this.setState({isRefresh:false})
      },5000)
    })
    return false
  }

  //Calendar: Click on an appointment to display the current operation history
  openAppointmentHistory = () => {
    const { t } = this.props
    if (this.state.isOpenappointDrawer) {
      this.closesidebarlog()
      return
    }
    openLoading()
    BackendApi.get_appointment_history({ event_id: this.state.operation_app_id }).then(list => {
      if (list.success) {
        this.setState({
          appointmentLog: list.data,
          isOpenappointDrawer: true
        })
      } else {
        this.setState({
          isOpenappointDrawer: true
        })
      }
    }).catch(error => {
      errorTips(t('FlexCalendar:Get logs from backend failed, please try again later.'))
    }).finally(() => {
      closeLoading()
    })
  }

  //Calendar: Close operation history
  closesidebarlog = () => {
    this.setState({
      isOpenappointDrawer: false
    })
  }

  //Calendar:Appointed No Show, No Buy, cancel ... operation
  onNoBuyShowDelClick = async (event) => {
    const { t } = this.props;
    openLoading()
    try {
      let result = await BackendApi.appointment_other_operation(event)
      let message = ''
      if (result.success) {
        if (event.operation_type !== 'resend' && event.operation_type !== 'u_dp' && event.operation_type !== 'offer') {
          switch (event.operation_type) {
            case 'cancel':
              message = t('FlexCalendar:tips.CancelAppointment')
              break;
            case 'noshow':
              message = t('FlexCalendar:tips.noshowAppointment')
              break;
            case 'nobuy':
              message = t('FlexCalendar:tips.nobuyAppointment')
              break;
            default:
              message = t('FlexCalendar:tips.GeneralAppointment')
              break;
          }
          successTips(message)
          await this.switchCalendar(this.state.selectedStore, this.state.calendarDate, this.state.calendarView, this.state.selectedCalendar)
          this.closeAppDetailDialog()
        } else {
          switch (event.operation_type) {
            case 'u_dp':
              message = t('FlexCalendar:tips.editDescriptionAppointment')
              break;
            case 'offer':
              message = t('FlexCalendar:tips.campaignCodeAppointment')
              break;
            default:
              message = t('FlexCalendar:tips.MailResend')
          }
          successTips(message)
          await this.switchCalendar(this.state.selectedStore, this.state.calendarDate, this.state.calendarView, this.state.selectedCalendar)
        }
      } else {
        warningTips(t('FlexCalendar:tips.OtherOperationsFailed'))
      }
    } catch (error) {
      console.error('onNoBuyShowDelClick failed:', error)
      if(error.data.error_message.code == 40606){
        errorTips(t('FlexCalendar:tips.CancelEmailHasBeenSent'))
      }else if(error.data.error_message.code == 40103){
        errorTips(t('FlexCalendar:tips.NoAppointmentFound'))
      }else if(error.data.error_message.code == 40105){
        errorTips(t('FlexCalendar:tips.NoAppointmentIdFound'))
      }else{
        errorTips(t('FlexCalendar:tips.OtherOperationsFailed'))
      }
    } finally {
      closeLoading()
    }
  }

  // select Available Date
  selectAvailableDate = (date) => {
    if (this.state.availableDateMap?.hasOwnProperty(date)) {
      let timeMap = { ...this.state.availableDateMap[date] }
      this.setState({
        dateInAvailableDateMap: date,
        availableTimeMap: timeMap,
        timeInAvailableTimeMap: '',
        selectedAvailableTimeBlock: null,
      })
    }
  }

  onNextDateClickEvent = async () => {
    let calendarModel = this.state.selectedCalendar

    if (this.state.selectedAvailableTimeBlock) {
      calendarModel = this.state.selectedAvailableTimeBlock.calendarModel
    }
    if (this.state.dateInAvailableDateMap) {
      let searchDate = this.state.dateInAvailableDateMap
      if (this.state.calendarDate && moment(this.state.calendarDate).isSame(moment(searchDate), 'day')) {
        let dateList = Object.keys(this.state.availableDateMap)
        let index = dateList.indexOf(this.state.dateInAvailableDateMap) + 1
        if (index < dateList.length) {
          searchDate = dateList[index]
          this.selectAvailableDate(searchDate)
        }
      }
      this.switchCalendarAction(
        this.state.selectedStore,
        moment(searchDate).toDate(),
        this.state.calendarView,
        calendarModel,
      )
    } else {
      if (Object.keys(this.state.availableDateMap).length > 0) {
        let searchDate = Object.keys(this.state.availableDateMap)[0]
        this.switchCalendarAction(
          this.state.selectedStore,
          moment(searchDate).toDate(),
          this.state.calendarView,
          calendarModel,
        )
        this.selectAvailableDate(searchDate)
      }
    }
  }

  onNextDateClickCreateEvent = () => {
    const { t } = this.props;
    if (this.state.selectedAvailableTimeBlock) {
      this.openCreateAppDialog(this.state.selectedAvailableTimeBlock)
    } else {
      errorTips(t('FlexCalendar:Please select a date time first!'))
    }
  }

  //Calendar:Click on an appointment found, the calendar will jump to the appointment date
  onKeyupappResult = (event, newValue) => {
    this.setState({
      select_keyup_id: event.target.value
    })
    this.state.keyup_result?.forEach(appointment => {
      if (appointment.event_id && appointment.event_id == event.target.value) {
        this.setState({ highlightEventId: appointment.event_id })
        this.searchStoreAction(appointment.optician_ref, false, this.state.filterLastMinute, moment(appointment.appointment_time).toDate())
      }
    })
  }

  //Search appointment and Calendar: Search and appointment general method
  onKeyupSearchAppointment = (store_id, startNumber, endNumber, tabs_value) => {
    const { t } = this.props;
    console.info(startNumber, endNumber)
    openLoading(t('FlexCalendar:tips.loading'))
    console.info(this.state.keyup_condition)
    let condition = ""
    if (tabs_value == '0') {
      condition = this.state.keyup_condition
    } else {
      condition = this.state.tabTwoCondition
    }

    let event = {
      store_id: '', condition: condition,
      event_id: this.state.tabTwoEventId, del_status: this.state.tabTwoDelStatus,
      start_number: startNumber, end_number: endNumber
    }
    BackendApi.search_keyup_appointment(event).then(list => {
      console.info(list)
      if (list.success) {
        if (store_id != '') {
          this.setState({
            keyup_result: list?.data?.result || [],
            appointmentRowsCount: list.data.total_number,
            start_number_of_rows: startNumber,
            end_number_of_rows: endNumber,
          }, () => {
            closeLoading()
          })
        } else {
          this.setState({
            appointmentRows: list.data.result != null ? list.data.result : [],
            newPageAppointmentRows: list.data.result != null ? list.data.result : [],
            appointmentRowsCount: list.data.total_number,
            start_number_of_rows: startNumber,
            end_number_of_rows: endNumber,
          }, () => {
            closeLoading()
          })
        }
      } else {
        closeLoading()
      }
    }).catch(error => {
      console.error('onKeyupSearchAppointment', error)
      closeLoading()
    }).finally(() => {
      this.setState({ appointmentSearchDisabled: false })
    })
  }

  //Search appointment:Cancel an appointment in the appointment list
  OnDelAppointment = (event_id) => {
    this.setState({
      listDelAppointment: !this.state.listDelAppointment,
      listDelAppointmentId: this.state.listDelAppointmentId == event_id ? '' : event_id
    })
  }
  onDelDescriptionBlur = (e) => {
    const { t } = this.props;
    console.info(e.target.value)
    if (e.target.value != '') {
      this.setState({
        listDelAppointmentDep: e.target.value,
        delDescriptionErrorTips: ''
      }, () => {
        openConfirmDialog(
          t('FlexCalendar:title.confirmCancelApp'),
          this.onListDel,
          t('FlexCalendar:message.confirmCancelAppDescription'),
          t('FlexCalendar:button.yesDeleteAppointment'),
          t('FlexCalendar:button.noKeepAppointment')
        )
      })
    } else {
      this.setState({
        delDescriptionErrorTips: t('FlexCalendar:tips.cancelErrorTips')
      })
    }
  }
  onDelDescriptionKeyUp = (e) => {
    if (e.keyCode == 13) {
      this.onDelDescriptionBlur(e)
    }
  }
  onListDel = () => {
    const { t, authUser } = this.props;
    openLoading()
    BackendApi.appointment_other_operation({
      event_id: this.state.listDelAppointmentId,
      operation_type: 'cancel',
      operating_id: authUser.id,
      description: this.state.listDelAppointmentDep
    }).then(result => {
      closeLoading()
      console.info(result)
      if (result.success) {
        this.setState({
          listDelAppointment: !this.state.listDelAppointment,
          listDelAppointmentId: '',
          listDelAppointmentDep: ''
        }, () => {
          this.onTwoSearchAppointment()
        })
      } else {
        errorTips(t('FlexCalendar:tips.failedToDelete'))
      }
    }).catch(error => {
      console.error('onListDel failed:', error)
      closeLoading()
      errorTips(t('FlexCalendar:tips.failedToDelete'))
    })
  }

  //Search appointment:Query the appointment list according to the conditions
  onAppointmentIdSearchChange = (e) => {
    this.setState({
      tabTwoEventId: e.target.value
    }, () => {
      if (this.state.tabTwoEventId.length > 4) {
        this.onTwoSearchAppointment()
      }
    })
  }
  onAppointmentNumberNameSearchChange = (e) => {
    this.setState({
      tabTwoCondition: e.target.value
    }, () => {
      if (this.state.tabTwoCondition.length > 4) {
        this.onTwoSearchAppointment()
      }
    })
  }
  onAppointmentSearchChange = (e) => {
    console.info(e.target.checked)
    this.setState({
      tabTwoDelStatus: e.target.checked ? true : false
    }, () => {
      this.onTwoSearchAppointment()
    })
  }
  onTwoSearchAppointment = () => {
    this.onKeyupSearchAppointment('', this.state.startRowsNumber, this.state.endRowsNumber, this.state.tab_value)
  }

  //Search appointment:Appointment list data paging
  onPageChange = (event, newPage) => {
    this.setState({
      rowsPerPage: newPage
    }, () => {
      this.PagingAccordingToData(this.state.rowsPerPage, this.state.rows_perpage_options)
    })
  };

  handleChangeRowsPerPage = (e) => {
    this.setState({
      rows_perpage_options: e.target.value
    }, () => {
      this.PagingAccordingToData(this.state.rowsPerPage, this.state.rows_perpage_options)
    })
  }

  PagingAccordingToData = (page, rows) => {
    let start_number = page * rows
    let end_number = start_number + rows
    this.onKeyupSearchAppointment('', start_number, end_number, 0)
  }

  //Tabs:Switch tabs
  onTabsChange = (event, newValue) => {
    if (newValue == 1) {
      this.setState({
        tabs_value: newValue
      }, () => {
        this.onKeyupSearchAppointment('', this.state.startRowsNumber, this.state.endRowsNumber, newValue)
      })
    } else {
      this.setState({
        tabs_value: newValue
      })
    }
  };

  //GDPR
  onGdprEmailchange = (e) => {
    const { t, email_reg } = this.props;

    if (email_reg.test(e.target.value)) {
      this.setState({
        emailGdprErrorTips: '',
        gdprEmail: e.target.value,
        customerRefGdprErrorTips: '',
        orderNumberGdprErrorTips: '',
        gdprCustomerRef: '',
        gdprOrderId: '',
        gdpr: {
          ...this.state.gdpr,
          unsubscribeFromNewsletter: e.target.value == '' ? false : true,
          addToDoNotCallList: false,
          deleteCustomerDetails: e.target.value == '' ? false : true,
          unsubscribeFromAll: e.target.value == '' ? false : true,
          unsubscribeFromLetter: e.target.value == '' ? false : true,
          unsubscribeFromSms: false,
          unsubscribe_points_news: e.target.value == '' ? false : true,
          unsubscribe_whatsapp: e.target.value == '' ? false : true,
        }
      })
    } else {
      this.setState({
        gdprEmail: e.target.value,
        customerRefGdprErrorTips: '',
        orderNumberGdprErrorTips: '',
        gdprCustomerRef: '',
        gdprOrderId: '',
        emailGdprErrorTips: e.target.value == '' ? '' : t('FlexCalendar:tips.emailGdpr'),
        gdpr: {
          ...this.state.gdpr,
          unsubscribeFromNewsletter: false,
          addToDoNotCallList: false,
          deleteCustomerDetails: false,
          unsubscribeFromAll: false,
          unsubscribeFromLetter: false,
          unsubscribeFromSms: false,
          unsubscribe_points_news: false,
          unsubscribe_whatsapp: false
        }
      })
    }
  }

  onGdprRefOrderchange = (e) => {
    const { t, email_reg } = this.props;
    if (e.target.value) {
      this.setState({
        emailGdprErrorTips: '',
        customerRefGdprErrorTips: '',
        orderNumberGdprErrorTips: '',
        gdprCustomerRef: e.target.id==='customerRefGdpr'? e.target.value:'',
        gdprOrderId: e.target.id==='orderGdpr'?e.target.value:'',
        gdprEmail: '',
        gdprphone: '',
        gdpr: {
          ...this.state.gdpr,
          unsubscribeFromNewsletter: e.target.value == '' ? false : true,
          addToDoNotCallList: e.target.value == '' ? false : true,
          deleteCustomerDetails: e.target.value == '' ? false : true,
          unsubscribeFromAll: e.target.value == '' ? false : true,
          unsubscribeFromLetter: e.target.value == '' ? false : true,
          unsubscribeFromSms: e.target.value == '' ? false : true,
          unsubscribe_points_news: e.target.value == '' ? false : true,
          unsubscribe_whatsapp: e.target.value == '' ? false : true,
        }
      })
    } else {
      this.setState({
        gdprEmail: '',
        gdprphone: '',
        gdprCustomerRef: e.target.id==='customerRefGdpr'?e.target.value:'',
        gdprOrderId: e.target.id==='orderGdpr'?e.target.value:'',
        emailGdprErrorTips: '',
        customerRefGdprErrorTips: '',
        orderNumberGdprErrorTips: '',
        gdpr: {
          ...this.state.gdpr,
          unsubscribeFromNewsletter: false,
          addToDoNotCallList: false,
          deleteCustomerDetails: false,
          unsubscribeFromAll: false,
          unsubscribeFromLetter: false,
          unsubscribeFromSms: false,
          unsubscribe_points_news: false,
          unsubscribe_whatsapp: false
        }
      })
    }
  }

  submissionGdpr = () => {
    const { t, selectedContact, authUser, email_reg } = this.props;
    let gdpr_info = this.state.gdpr
    let verify = true
    let emailError = ''
    console.info(this.state.gdprphone)
    if (this.state.gdprEmail != '') {
      if (email_reg.test(this.state.gdprEmail)) {
        verify = true
        emailError = ''
      } else {
        verify = false
        emailError = t('FlexCalendar:tips.emailGdpr')
      }
    }
    this.setState({
      emailGdprErrorTips: emailError
    })
    if (verify) {
      let status = this.state.gdpr.unsubscribeFromAll ||
        this.state.gdpr.unsubscribeFromLetter ||
        this.state.gdpr.unsubscribeFromNewsletter ||
        this.state.gdpr.unsubscribeFromSms ||
        this.state.gdpr.addToDoNotCallList ||
        this.state.gdpr.deleteCustomerDetails ||
        this.state.gdpr.unsubscribe_points_news ||
        this.state.gdpr.unsubscribe_whatsapp
      if (status) {
        if (this.state.gdprphone != '' || this.state.gdprEmail != '' || (this.state.gdprCustomerRef).trim()!='' || (this.state.gdprOrderId).trim()!='') {
          openLoading(t('FlexCalendar:tips.GdprLoading'))
          gdpr_info['email'] = this.state.gdprEmail
          gdpr_info['phone'] = this.state.gdprphone
          gdpr_info['customer_ref'] = (this.state.gdprCustomerRef).trim()
          gdpr_info['order_number'] = (this.state.gdprOrderId).trim()
          gdpr_info['operating_id'] = authUser.id
          gdpr_info['lead_id'] = selectedContact ? selectedContact.lead_id : ''
          gdpr_info['customer_no'] = selectedContact ? selectedContact.customer_no : ''
          // if (!this.state.gdpr.unsubscribeFromAll){
          //   delete gdpr_info['unsubscribeFromAll']
          // }
          // if (!this.state.gdpr.unsubscribeFromLetter){
          //   delete gdpr_info['unsubscribeFromLetter']
          // }
          // if (!this.state.gdpr.unsubscribeFromNewsletter){
          //   delete gdpr_info['unsubscribeFromNewsletter']
          // }
          // if (!this.state.gdpr.unsubscribeFromSms){
          //   delete gdpr_info['unsubscribeFromSms']
          // }
          // if (!this.state.gdpr.addToDoNotCallList){
          //   delete gdpr_info['addToDoNotCallList']
          // }
          // if (!this.state.gdpr.deleteCustomerDetails){
          //   delete gdpr_info['deleteCustomerDetails']
          // }
          // if (!this.state.gdpr.unsubscribe_points_news){
          //   delete gdpr_info['unsubscribe_points_news']
          // }
          // if (!this.state.gdpr.unsubscribe_whatsapp){
          //   delete gdpr_info['unsubscribe_whatsapp']
          // }
          console.info(gdpr_info)
          BackendApi.available_lead_customer_gdpr(gdpr_info).then(result => {
            if (result.success) {
              if (result.data.status_code == 200) {
                successTips(t('FlexCalendar:tips.gdprOperationSuccess'))
              } else if (result.data.status_code == 403) {
                errorTips(t('FlexCalendar:tips.gdprOperationError'))
              }
            }
            closeLoading()
          }).catch(error => {
            console.error('available_lead_customer_gdpr', error)
            closeLoading()
            errorTips(t('FlexCalendar:tips.gdprOperationError'))
          })
        } else {
          warningTips(t('FlexCalendar:tips.gdprPhoneEmailNullError'))
        }
      } else {
        warningTips(t('FlexCalendar:tips.noGdprOperationSelected'))
      }
    }
  }

  // Retention Time

  closeRetentionTimeDialog = () => {
    this.setState({ isOpenRetentionTimeDialog: false })
  }

  onOpenCreateRetentionTimeDialog = () => {
    const { t, authUser } = this.props;
    console.info("authUser", authUser)
    if (this.state.selectedStore) {
      this.setState({
        isOpenRetentionTimeDialog: true
      })
    } else {
      warningTips(t('FlexCalendar:Please select a store first.'))
    }
  }

  onCreateRetentionTime = async (event) => {
    const { t, authUser } = this.props;
    openLoading()
    event['operating_id'] = authUser.id;
    event['store_id'] = this.state.selectedStore.id;
    try {
      let result = await BackendApi.add_retention(event)
      console.log('Create Retention Success. Result:', result)
      successTips(t('FlexCalendar:Retention Create Success!'))
      await this.switchCalendar(this.state.selectedStore, this.state.calendarDate, this.state.calendarView, this.state.selectedCalendar)
      this.closeRetentionTimeDialog()
    } catch (error) {
      console.log('Create Retention Failed.', error)
      warningTips(t('FlexCalendar:Create Retention failed, please try again!'))
    } finally {
      closeLoading()
    }
  }

  onEditRetentionTime = async (event) => {
    const { t, authUser } = this.props;
    event['operating_id'] = authUser.id;
    openLoading()
    try {
      let result = await BackendApi.edit_retention(event)
      console.log('Edit Retention Success. Result:', result)
      successTips(t('FlexCalendar:Retention Edit Success!'))
      await this.switchCalendar(this.state.selectedStore, this.state.calendarDate, this.state.calendarView, this.state.selectedCalendar)
      this.closeAppDetailDialog()
    } catch (error) {
      console.log('Edit Retention Failed.', error)
      warningTips(t('FlexCalendar:Edit Retention failed, please try again!'))
    } finally {
      closeLoading()
    }
  }

  onCancelRetentionTime = async (event) => {
    const { t, authUser } = this.props;
    event['operating_id'] = authUser.id;
    openLoading()
    try {
      let result = await BackendApi.cancel_retention(event)
      console.log('Cancel Retention Success. Result:', result)
      successTips(t('FlexCalendar:Retention Cancel Success!'))
      await this.switchCalendar(this.state.selectedStore, this.state.calendarDate, this.state.calendarView, this.state.selectedCalendar)
      this.closeAppDetailDialog()
    } catch (error) {
      console.log('Cancel Retention Failed.', error)
      warningTips(t('FlexCalendar:Cancel Retention failed, please try again!'))
    } finally {
      closeLoading()
    }
  }

  render() {
    const { t, selectedEvent, offers, authUser } = this.props
    let formatstr = (authUser?.lang === 'en_US') ? 'MM-DD-YYYY HH:mm:ss' : 'DD-MM-YYYY HH:mm:ss'
    let nowdate = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
    let selectStoreNo = this.state.selectedStore?.ref || ''
    let selectStoreName = this.state.selectedStore?.display_name || ''
    let selectStorePhone = this.state.selectedStore?.phone || ''
    let selectStoreMobile = this.state.selectedStore?.mobile || ''
    let selectStoreStreet = this.state.selectedStore?.street || ''
    let selectStoreStreet2 = this.state.selectedStore?.street2 || ''
    selectStoreStreet = selectStoreStreet + selectStoreStreet2
    let selectStoreCity = this.state.selectedStore?.city || ''
    let selectStoreZip = this.state.selectedStore?.zip || ''
    let selectStoreAddress = this.state.selectedStore?.address_human || ''
    let code = getCurrentCountry()
    let internalEmailP = new RegExp(".+@(brillen\\.((at)|(de)|(jp)|(com)|(pl)|(co\\.uk)))|(gafas\\.es)|(occhiali24\\.it)|(supervista\\.de)")
    let internalUser = authUser?.id === 2 || internalEmailP.test(authUser?.email)
    return (
      <React.Fragment>
        <AppBar position="static" style={{ padding: '5px', background: 'transparent', boxShadow: 'none' }}>
          <Tabs value={this.state.tabs_value} onChange={this.onTabsChange} style={{ background: '#fff', color: '#000', border: '1px solid #fff', textAlign: 'center' }}>
            <Tab value={0} label={t('FlexCalendar:Calendar')} style={{ width: '33%', maxWidth: '33%' }} />
            {/* <Tab label="Search appointment" style={{ width: '33%', maxWidth: '33%' }} /> */}
            <Tab value={2} label={t('FlexCalendar:GDPR')} style={{ width: '33%', maxWidth: '33%' }} />
          </Tabs>
        </AppBar>
        {this.state.tabs_value === 0 ?
          <CalendarAreaContainer>
            <Paper style={{ padding: "8px", marginTop: "5px" }}>
              <TextField
                select
                label={t('FlexCalendar:pleaseSelectAnOptician')}
                variant="filled"
                style={{ width: "8cm", margin: "1ch", cursor: "pointer" }}
                value={this.state.storeInMap}
                onChange={(event) => {
                  this.setState({ storeInMap: event.target.value })
                  this.showStoreAction(this.state.storeMap[event.target.value])
                }}
                onMouseDown={(event) => Object.keys(this.state.storeMap).length === 0 && !this.state.optician_search && !this.state.optician_zip_search && this.searchStoreAction(false, false, this.state.filterLastMinute)}
              >
                {
                  this.state.storeMap && Object.keys(this.state.storeMap).map((storeRef) => {
                    let item = this.state.storeMap[storeRef]
                    return <MenuItem key={item.id} value={item.ref} style={{ background: 
item.partner_type === 'OWN' ? (item.optician_status === 'CLOSE' ? (item.tags.indexOf('calendar only')!=-1?'linear-gradient(90deg, rgb(104 136 176) 10%, rgba(148,193,30,1) 20%, rgba(205,92,92,1) 100%)':'linear-gradient(90deg, rgba(148,193,30,1) 20%, rgba(205,92,92,1) 100%)') : (item.tags.indexOf('calendar only')!=-1?'linear-gradient(90deg, rgb(104 136 176) 20%, rgba(148,193,30,1) 100%)':'#94c11e')) : (item.optician_status === 'CLOSE' ? (item.tags.indexOf('calendar only')!=-1?'linear-gradient(90deg, rgb(104 136 176) 20%, rgba(205,92,92,1) 100%)':'#cd5c5c') : (code === 'it' && item.partner_type === 'DSH'?(item.tags.indexOf('calendar only')!=-1?'linear-gradient(90deg, rgb(104 136 176) 20%, rgba(148,193,30,1) 100%)':'#94c11e'):((item.tags.indexOf('calendar only')!=-1?'rgb(104 136 176)':'')))) }}>
                      {item.ref + ' - ' + item.street + ' - ' + item.display_name + ' - ' + item.city}
                      {item.distance !== 0 ? ' - (' + (code === 'gb' || code === 'us' ? (item.distance * 0.62137).toFixed(2) : item.distance) + ' ' + (code === 'gb' || code === 'us' ? 'miles' : t('FlexCalendar:label.distance')) + ')' : null}
                      {item.optician_status === 'CLOSE' ? ` ( ${t('FlexCalendar:Closed')} )` : ''}
                    </MenuItem>
                  })
                }
              </TextField>
              <TextField
                id="optician_search"
                label={t('FlexCalendar:Optician Search')}
                variant="outlined"
                color="secondary"
                style={{ margin: "1ch" }}
                value={this.state.optician_search}
                disabled={this.state.opticianSearchDisabled}
                onChange={e => this.setState({ optician_search: e.target.value })}
                onKeyDown={(e) => { if (e.key === 'Enter' && e.target.value) this.searchStoreAction(e.target.value, false, this.state.filterLastMinute) }}
              />
              <TextField
                id="optician_zip_search"
                label={t('FlexCalendar:Zipcode/City')}
                variant="outlined"
                color="secondary"
                style={{ margin: "1ch" }}
                value={this.state.optician_zip_search}
                disabled={this.state.zipSearchDisabled}
                onChange={e => this.setState({ optician_zip_search: e.target.value })}
                onKeyDown={(e) => { if (e.key === 'Enter' && e.target.value) this.searchStoreAction(false, e.target.value, this.state.filterLastMinute) }}
              />
              <Button onClick={() => this.searchStoreAction(this.state.optician_search, this.state.optician_zip_search, this.state.filterLastMinute)} style={{ margin: "2ch 1ch" }} variant="contained" color="primary">
                {t('FlexCalendar:Search')}
              </Button>
              {this.state.emptyLastMinuteStore ?
                <>
                  <br />
                  <label style={{ fontSize: '0.75rem', color: 'red', margin: '4px 14px 0px 14px' }}>
                    {t('FlexCalendar:There are no stores that support Last Minute Offer in the left list')}
                  </label>
                </> : null}
            </Paper>
            {this.state.selectedStore ?
              <Paper style={{ padding: "8px", marginTop: "10px" }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <Typography>
                      <strong>{t('FlexCalendar:label.opticianName')}</strong> :
                      <span>{selectStoreName}</span>
                      {this.state.selectedStore?.optician_status === 'CLOSE' ? <span style={{ color: "red", fontWeight: 'bold' }}>{` ( ${t('FlexCalendar:Closed')} )`}</span> : null}
                    </Typography>
                    <Typography><strong>{t('FlexCalendar:label.opticianNo')}</strong> : <span>{selectStoreNo}</span></Typography>
                    <Typography><strong>{t('label.address')}</strong> : <span >{selectStoreAddress}</span></Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography><strong>{t('label.mobile')}</strong> : <span>{selectStoreMobile}</span></Typography>
                    <Typography><strong>{t('label.phone')}</strong> : <span>{selectStorePhone}</span></Typography>
                    <div><strong>{t('FlexCalendar:Ability')}</strong> :
                      {Object.keys(this.state.selectedStore.ability || {}).map((k) => {
                        return <Chip size="small" color="primary" key={k} label={this.state.selectedStore.ability[k]} />
                      })}
                    </div>
                  </Grid>
                </Grid>
              </Paper>
              : null
            }
            {this.state.selectedStore?.supportLastMinute &&
              <Paper style={{ padding: "8px", marginTop: "10px", textAlign: "center" }}>
                <div>
                  <strong style={{ fontSize: "18px" }}>{t('label.lastMinuteOffers')}</strong>
                  &nbsp;
                  <LoadingButton
                    style={{ lineHeight: "unset" }}
                    size="small"
                    onClick={() => this.fetchLastMinuteAds(this.state.selectedStore)}
                    loading={this.state.loadingLastMinute}
                    loadingIndicator={t('FlexCalendar:Loading…')}
                    variant="contained"
                  >
                    {t('FlexCalendar:Refresh')}
                  </LoadingButton>
                </div>
                {!this.state.loadingLastMinute && this.state.BusinessHoursList &&
                  <Grid item xs={12} sm={12} >
                    {this.state.BusinessHoursList.map((list, index) => {
                      let lableText = ""
                      let afertLableText = ""
                      let offerOnePrice = ""
                      let offerTwoPrice = ""
                      let afertOnePrice = ""
                      let afertTwoPrice = ""
                      let lastMinutes = t('label.lastMinuteDate') + '-'
                      let LDate = list.date
                      let country_code = getCurrentCountry()
                      if (index === 0) {
                        if (index !== 1) {
                          lableText = t('label.lastMinuteSameDayDescription')
                        } else {
                          lableText = t('label.lastMinuteNextDayDescription')
                        }
                        if (country_code === 'de' || country_code === 'at') {
                          offerOnePrice = t("50€ ")
                          offerTwoPrice = t("129€")
                        } else if (country_code === 'gb') {
                          offerOnePrice = t("£50 ")
                          offerTwoPrice = t("£129")
                        } else if (country_code === 'us') {
                          offerOnePrice = t("$50 ")
                          offerTwoPrice = t("$129")
                        } else if (country_code === 'pl') {
                          offerOnePrice = t("50zl ")
                          offerTwoPrice = t("129zl")
                        } else {
                          if (index !== 1) {
                            offerOnePrice = t('label.SameDayDiscountedPrice')
                            offerTwoPrice = t('label.SameDayAfterDiscountPrice')
                          } else {
                            offerOnePrice = t('label.NextDayDiscountedPrice')
                            offerTwoPrice = t('label.NextDayAfterDiscountPrice')
                          }
                        }

                      } else if (index === 1) {
                        lableText = t('label.lastMinuteNextDayDescription')
                        if (country_code === 'de' || country_code === 'at') {
                          offerOnePrice = t("40€ ")
                          offerTwoPrice = t("119€")
                        } else if (country_code === 'gb') {
                          offerOnePrice = t("£40 ")
                          offerTwoPrice = t("£119")
                        } else if (country_code === 'us') {
                          offerOnePrice = t("$40 ")
                          offerTwoPrice = t("$119")
                        } else if (country_code === 'pl') {
                          offerOnePrice = t("40zl ")
                          offerTwoPrice = t("119zl")
                        } else {
                          offerOnePrice = t('label.NextDayDiscountedPrice')
                          offerTwoPrice = t('label.NextDayAfterDiscountPrice')
                        }
                      } else if (index === 2) {
                        lableText = t('label.lastMinuteAfterNextDayDescription')
                        if (country_code === 'de' || country_code === 'at') {
                          offerOnePrice = t("30€ ")
                          offerTwoPrice = t("109€")
                        } else if (country_code === 'gb') {
                          offerOnePrice = t("£30 ")
                          offerTwoPrice = t("£109")
                        } else if (country_code === 'us') {
                          offerOnePrice = t("$30 ")
                          offerTwoPrice = t("$109")
                        } else if (country_code === 'pl') {
                          offerOnePrice = t("30zl ")
                          offerTwoPrice = t("109zl")
                        } else {
                          offerOnePrice = t('label.AfterNextDayDiscountedPrice')
                          offerTwoPrice = t('label.AfterNextDayAfterDiscountPrice')
                        }
                      } else if (index === 3) {
                        lableText = t('label.lastMinuteAfterTomorrowDescription')
                        // afertLableText = t('label.lastMinuteAfterwardsDescription')
                        if (country_code === 'de' || country_code === 'at') {
                          offerOnePrice = t("20€ ")
                          offerTwoPrice = t("99€")
                        } else if (country_code === 'gb') {
                          offerOnePrice = t("£20 ")
                          offerTwoPrice = t("£99")
                        } else if (country_code === 'us') {
                          offerOnePrice = t("$20 ")
                          offerTwoPrice = t("$99")
                        } else if (country_code === 'pl') {
                          offerOnePrice = t("20zl ")
                          offerTwoPrice = t("99zl")
                        } else {
                          offerOnePrice = t('label.AfterTomorrowDiscountedPrice')
                          offerTwoPrice = t('label.AfterTomorrowAfterDiscountPrice')
                        }
                      } else if (index === 4) {
                        lableText = t('label.lastMinuteAfterwardsDescription')
                        if (country_code === 'de' || country_code === 'at') {
                          offerOnePrice = t("10€ ")
                          offerTwoPrice = t("89€")
                        } else if (country_code === 'gb') {
                          offerOnePrice = t("£10 ")
                          offerTwoPrice = t("£89")
                        } else if (country_code === 'us') {
                          offerOnePrice = t("$10 ")
                          offerTwoPrice = t("$89")
                        } else if (country_code === 'pl') {
                          offerOnePrice = t("10zl ")
                          offerTwoPrice = t("89zl")
                        } else {
                          offerOnePrice = t('label.LaterDiscountedPrice')
                          offerTwoPrice = t('label.LaterAfterDiscountPrice')
                        }
                      }
                      if (index === 0 || index === 1 || index === 2 || index === 4) {
                        return (
                          <Grid key={index} item xs={12} sm={12}>
                            <Typography>{lastMinutes}<span style={{ color: "red" }}>{LDate}</span>-<strong style={{ color: "#b20dbd" }}>{offerOnePrice}</strong>{lableText}=&gt;<strong style={{ color: "#b20dbd" }}>{offerTwoPrice}</strong></Typography>
                          </Grid>
                        )
                      } else if (index === 3) {
                        return (
                          <Grid key={index} item xs={12} sm={12}>
                            <Grid item xs={12} sm={12}><Typography>{lastMinutes}<span style={{ color: "red" }}>{LDate}</span>-<strong style={{ color: "#b20dbd" }}> {offerOnePrice}</strong>{lableText}=&gt;<strong style={{ color: "#b20dbd" }}>{offerTwoPrice}</strong></Typography>
                            </Grid>
                            {/* <Grid item xs={12} sm={12}><Typography>{lastMinutes}<span style={{ color: "red" }}>{LDate}</span>-<strong style={{ color: "#b20dbd" }}> {afertOnePrice}</strong>{afertLableText}=&gt;<strong style={{ color: "#b20dbd" }}>{afertTwoPrice}</strong></Typography>
                          </Grid> */}
                          </Grid>
                        )
                      }
                    })
                    }
                  </Grid>
                }
              </Paper>
            }
            {this.state.selectedStore ?
              <Paper style={{ padding: "8px", marginTop: "10px" }}>
                <Grid style={{ paddingLeft: "5px", width: '50%', border: "1px solid rgba(0, 0, 0, 0.12)", display: "inline-block", 'verticalAlign': "text-bottom" }}>
                  <LocalizationProvider dateAdapter={AdapterDateFns} style={{ width: '20%' }}>
                    <DesktopDatePicker
                      margin="normal"
                      id="date-picker-dialog"
                      label={t('FlexCalendar:Date picker')}
                      views={['year', 'month', 'day']}
                      value={moment(this.state.prejumpdate, 'YYYY-MM-DD')}
                      style={{ width: '12ch' }}
                      onChange={(date) => this.setState({ prejumpdate: date || new Date() })}
                      renderInput={(params) => <TextField {...params} />} />
                  </LocalizationProvider>
                  <Button onClick={() => this.switchCalendarAction(this.state.selectedStore, this.state.prejumpdate, this.state.calendarView, this.state.selectedCalendar)} style={{ margin: "2ch 2px" }} variant="contained" color="primary">
                    {t('FlexCalendar:button.goToTheSelectDate')}
                  </Button>
                  <Button onClick={() => this.switchCalendarAction(this.state.selectedStore, this.state.calendarDate, this.state.calendarView, this.state.selectedCalendar)} style={{ margin: "2ch 2px" }} variant="contained" color="primary">
                    {t('FlexCalendar:button.Refresh')}
                  </Button>
                  {internalUser ?
                    <Button onClick={this.onOpenCreateRetentionTimeDialog} style={{ margin: "2ch 0ch" }} variant="contained" color="primary">
                      {t('FlexCalendar:button.RetentionTime')}
                    </Button> : null
                  }
                </Grid>
                <Grid style={{ padding: "3px", display: "inline-block", 'verticalAlign': "text-bottom" }}>
                  <TextField
                    label={t('FlexCalendar:Search appointment')}
                    InputProps={{
                      startAdornment:
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>,
                    }}
                    style={{ width: "4cm", margin: "1ch", cursor: "pointer" }}
                    variant="outlined"
                    value={this.state.keyup_condition}
                    onChange={(e) => this.setState({ keyup_condition: e.target.value })}
                    disabled={this.state.appointmentSearchDisabled}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && e.target.value) {
                        this.setState({
                          appointmentSearchDisabled: true
                        }, () => {
                          this.onKeyupSearchAppointment(this.state.selectedStore.ref, this.state.startRowsNumber, this.state.endRowsNumber, 0)
                        })
                      }
                    }}
                  />
                  <TextField
                    select
                    label={t('FlexCalendar:label.appResult')}
                    variant="filled"
                    style={{ width: "3cm", margin: "1ch", cursor: "pointer" }}
                    value={this.state.select_keyup_id}
                    onChange={this.onKeyupappResult}
                  >
                    {this.state.keyup_result &&
                      this.state.keyup_result.filter(item => item.operation_status !== "CANCEL").map((list) => (
                        <MenuItem key={list.event_id} value={list.event_id}>
                          {list.name + ';' + (list.phone != '' ? list.phone : list.mobile) + ';' + list.email}
                        </MenuItem>
                      ))
                    }
                  </TextField>
                </Grid>
                <Grid style={{ padding: "0px", border: "1px solid rgba(0, 0, 0, 0.12)", display: "inline-block" }}>
                  <TextField
                    select
                    label={t('FlexCalendar:label.appDate')}
                    variant="filled"
                    style={{ width: "4cm", margin: "1ch", cursor: "pointer" }}
                    value={this.state.dateInAvailableDateMap}
                    onChange={(e) => this.selectAvailableDate(e.target.value)}
                  >
                    {this.state.availableDateMap &&
                      Object.keys(this.state.availableDateMap).map((date, index) => (
                        <MenuItem key={index} value={date}>{date}</MenuItem>
                      ))
                    }
                  </TextField>
                  <TextField
                    select
                    label={t('FlexCalendar:label.appTime')}
                    variant="filled"
                    style={{ width: "3cm", margin: "1ch", cursor: "pointer" }}
                    value={this.state.timeInAvailableTimeMap}
                    onChange={(e) => {
                      this.setState({
                        timeInAvailableTimeMap: e.target.value,
                        selectedAvailableTimeBlock: { ...this.state.availableTimeMap[e.target.value] }
                      })
                    }}
                  >
                    {this.state.availableTimeMap &&
                      Object.keys(this.state.availableTimeMap).map((key, index) => {
                        return (<MenuItem key={index} value={key}>{this.state.availableTimeMap[key].title}</MenuItem>)
                      })
                    }
                  </TextField>
                  <Button onClick={this.onNextDateClickEvent} style={{ margin: "2ch 1ch" }} variant="contained" color="primary">
                    {t('FlexCalendar:button.nextDate')}
                  </Button>
                  <Grid>
                    <Button onClick={this.onNextDateClickCreateEvent} style={{ margin: "2ch 1ch" }} variant="contained" color="primary">
                      {t('FlexCalendar:button.ChooseTimeHereToMakeAnAppointment')}
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
              : null
            }
            {this.state.inMovingEvent &&
              <Paper style={{ padding: "8px", marginTop: "5px" }}>
                <Alert
                  severity="warning"
                  action={
                    <Button color="inherit" size="small" onClick={this.stopMoveEvent} >
                      {t('FlexCalendar:button.cancelMove')}
                    </Button>}>
                  {t('FlexCalendar:tips.youAreMovingAppNow')}
                </Alert>
              </Paper>
            }
            {this.state.selectedStore ?
              <Paper style={{ padding: "8px", marginTop: "5px" }}>
                {this.state.selectedStore.calendarModels?.length >= 2 &&
                  <AppBar position="static" style={{ background: '#fff', color: '#000', margin: '0px', padding: '0px', boxShadow: 'none' }}>
                    <Tabs
                      value={this.state.selectedCalendar}
                      onChange={(event, switchedModel) => this.switchCalendarAction(this.state.selectedStore, false, this.state.calendarView, switchedModel)}>
                      {
                        this.state.selectedStore.calendarModels.map((item) => (
                          <Tab key={item} label={t('FlexCalendar:Calendar ' + item)} value={item} />
                        ))
                      }
                    </Tabs>
                  </AppBar>
                }
                {this.state.selectedCalendar && this.state.app_open_close_list?.length > 0 ? <Calendar
                  localizer={localizer}
                  startAccessor="start"
                  endAccessor="end"
                  views={['day', 'week']}
                  dayLayoutAlgorithm="no-overlap"
                  min={new Date(0, 0, 0, 7, 0, 0, 0)}
                  max={new Date(0, 0, 0, 21, 0, 0, 0)}
                  step={15} // 日历中一个小方块的事件跨度
                  // timeslots={2} // 纵轴方向上，相邻两个时间点之间，间隔的方块个数
                  messages={{
                    next: t('FlexCalendar:Next'),
                    day: t('FlexCalendar:Day'),
                    today: t('FlexCalendar:Today'),
                    week: t('FlexCalendar:Week'),
                    previous: t('FlexCalendar:Previous'),
                  }}
                  backgroundEvents={this.state.closed_block}
                  events={this.state.event_list}
                  date={this.state.calendarDate}
                  onNavigate={(date) => this.switchCalendarAction(this.state.selectedStore, date, this.state.calendarView, this.state.selectedCalendar)}
                  view={this.state.calendarView}
                  onView={(view) => this.switchCalendarAction(this.state.selectedStore, this.state.calendarDate, view, this.state.selectedCalendar)}
                  onDrillDown={(date) => this.switchCalendarAction(this.state.selectedStore, date, 'day', this.state.selectedCalendar)}
                  eventPropGetter={this.onEventPropGetter}
                  // onSelectSlot={this.onClickSlot}
                  onSelectEvent={this.onClickEvent}
                /> : null}
                {this.state.isOpenCreateAppDialog && <CreateAppDialog
                  open={this.state.isOpenCreateAppDialog}
                  CreateApp={this.CreateNewAppointment}
                  onClose={this.closeCreateAppDialog}
                  app_time={this.state.create_app_time}
                  offers={offers}
                  event_name={this.state.event_name}
                  event_offer={this.state.event_offer}
                  event_phone={this.state.event_phone}
                  event_mobile={this.state.event_mobile}
                  event_email={this.state.event_email}
                  event_firstname={this.state.event_firstname}
                  event_lastname={this.state.event_lastname}
                  zipcode={this.state.zipcode}
                  event_description={this.state.event_description}
                  BusinessHoursList={this.state.BusinessHoursList}
                />}
                {this.state.isOpenAppDetailDialog && <AppDetailDialog
                  open={this.state.isOpenAppDetailDialog}
                  onClose={() => this.closeAppDetailDialog()}
                  onEdit={this.openEditAppDialog}
                  onMove={this.startMoveEvent}
                  onRefresh={this.onRefreshEvent}
                  isRefresh={this.state.isRefresh}
                  operation_app_id={this.state.operation_app_id}
                  event_name={this.state.event_name}
                  event_offer={this.state.event_offer}
                  event_offervoucher={this.state.event_offervoucher}
                  event_phone={this.state.event_phone}
                  event_mobile={this.state.event_mobile}
                  event_email={this.state.event_email}
                  event_offerdescription={this.state.event_offerdescription}
                  event_firstname={this.state.event_firstname}
                  event_lastname={this.state.event_lastname}
                  zipcode={this.state.zipcode}
                  event_description={this.state.event_description}
                  event_not_buy_reason={this.state.event_not_buy_reason}
                  event_eye_test={this.state.event_eye_test}
                  event_start_date={this.state.event_start_date}
                  event_end_date={this.state.event_end_date}
                  event_start_time={this.state.event_start_time}
                  event_end_time={this.state.event_end_time}
                  event_mo={this.state.event_mo}
                  event_tu={this.state.event_tu}
                  event_we={this.state.event_we}
                  event_th={this.state.event_th}
                  event_fr={this.state.event_fr}
                  event_sa={this.state.event_sa}
                  event_su={this.state.event_su}
                  event_double_confirm_required={this.state.event_double_confirm_required}
                  event_double_confirm={this.state.event_double_confirm}
                  event_is_new={this.state.event_is_new}
                  event_remark={this.state.event_remark}
                  event_source={this.state.event_source}
                  openHistory={this.openAppointmentHistory}
                  onNoBuyShowDelClick={this.onNoBuyShowDelClick}
                  authUser={this.props.authUser}
                  internalUser={internalUser}
                  offers={offers}
                  event_status={this.state.event_status}
                  event_app_time={this.state.event_app_time}
                  BusinessHoursList={this.state.BusinessHoursList}
                  onEditRetentionTime={this.onEditRetentionTime}
                  onCancelRetentionTime={this.onCancelRetentionTime}
                />}
                {this.state.isOpenEditAppDialog && <CreateAppDialog
                  open={this.state.isOpenEditAppDialog}
                  onClose={() => this.setState({ isOpenEditAppDialog: false })}
                  app_time={this.state.app_time}
                  event_offer={this.state.event_offer}
                  event_description={this.state.event_description}
                  operation_app_id={this.state.operation_app_id}
                  event_name={this.state.event_name}
                  event_phone={this.state.event_phone}
                  event_mobile={this.state.event_mobile}
                  event_email={this.state.event_email}
                  event_firstname={this.state.event_firstname}
                  event_lastname={this.state.event_lastname}
                  zipcode={this.state.zipcode}
                  offers={offers}
                  BusinessHoursList={this.state.BusinessHoursList}
                />}
                {<Drawer open={this.state.isOpenappointDrawer} anchor="right" transitionDuration={500}>
                  <Box p={2} style={{ maxWidth: '430px' }}>
                    <Box m={2}>
                      <Grid container justifyContent='space-between' alignItems='center'>
                        <Typography variant="h6">
                          {t('FlexCalendar:Appointment History')}
                        </Typography>
                        <CloseIcon fontSize='large' color='secondary' onClick={this.closesidebarlog} />
                      </Grid>
                      <Divider style={{ marginTop: 8 }} />
                    </Box>
                    <Grid>
                      {this.state.appointmentLog.map((log, index) =>
                        <Grid className="containersidebar" key={index}>
                          <Grid className="contentlogdisp">
                            <Grid variant='body2' style={{ margin: 8 }}>
                              <Grid>
                                {moment(log.date).local().format('YYYY-MM-DD HH:mm:ss')}
                              </Grid>
                              <Grid dangerouslySetInnerHTML={{ __html: log.body }}>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                </Drawer>}
              </Paper>
              : null
            }
            {this.state.isOpenRetentionTimeDialog ?
              <RetentionTimeDialog
                open={this.state.isOpenRetentionTimeDialog}
                onClose={this.closeRetentionTimeDialog}
                CreateRetentionTime={this.onCreateRetentionTime}
              >
              </RetentionTimeDialog> : null
            }
          </CalendarAreaContainer>
          : null}
        {this.state.tabs_value == 1 ?
          <>
            <Paper style={{ padding: "8px", marginTop: "5px" }}>
              <Typography variant="h6">
                {t('FlexCalendar:label.searchAppointment')}
              </Typography>
              <TextField
                ref="appointment_id_search"
                id="appointment_id_search"
                label="Insert appointment ID"
                variant="outlined"
                color="secondary"
                style={{ margin: "1ch", width: '20%' }}
                value={this.state.tabTwoEventId}
                onChange={this.onAppointmentIdSearchChange.bind(this)}
              />
              <TextField
                ref="appointment_phone_number_name_search"
                id="appointment_phone_number_name_search"
                label="Search by Email, phone number or name"
                variant="outlined"
                color="secondary"
                style={{ margin: "1ch", width: '30%' }}
                value={this.state.tabTwoCondition}
                onChange={this.onAppointmentNumberNameSearchChange.bind(this)}
              />
              <FormControlLabel
                style={{ marginLeft: "1%", paddingTop: '1%' }}
                control={<Checkbox
                  checked={this.state.tabTwoDelStatus}
                  onChange={this.onAppointmentSearchChange} color="default" name="onlyDel" />}
                label={t('FlexCalendar:label.getOnlyDeleted')}
              />
            </Paper>
            <Paper style={{ padding: "8px", marginTop: "5px" }}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('FlexCalendar:column.id')}</TableCell>
                      <TableCell>{t('FlexCalendar:column.storeRef')}</TableCell>
                      <TableCell>{t('FlexCalendar:column.opticianName')}</TableCell>
                      <TableCell>{t('FlexCalendar:column.email')}</TableCell>
                      <TableCell>{t('FlexCalendar:column.leadSubject')}</TableCell>
                      <TableCell>{t('FlexCalendar:column.Description')}</TableCell>
                      <TableCell>{t('FlexCalendar:column.lastChangedByUser')}</TableCell>
                      <TableCell>{t('FlexCalendar:column.deleteUser')}</TableCell>
                      <TableCell>{t('FlexCalendar:column.deleteDate')}</TableCell>
                      <TableCell>{t('FlexCalendar:column.deleteDescriptionInfo')}</TableCell>
                      <TableCell>{t('FlexCalendar:column.createDate')}</TableCell>
                      <TableCell>{t('FlexCalendar:column.agentOption')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.newPageAppointmentRows.length > 0 ? this.state.newPageAppointmentRows.map(row => {
                      return (
                        <TableRow key={row.event_id} className={(row.operation_status !== 'CANCEL') ? '' : 'markers'}>
                          <TableCell>{row.event_id}</TableCell>
                          <TableCell>{row.optician_ref}</TableCell>
                          <TableCell>{row.optician_city + ' ' + row.optician_name}</TableCell>
                          <TableCell>{row.email}</TableCell>
                          <TableCell>
                            {row.name !== '' ? (row.name + ';\n') : ''}
                            {row.phone !== '' ? (row.phone + ';\n') : ''}
                            {row.mobile !== '' && row.phone === '' ? (row.mobile + ';\n') : ''}

                            {row.email !== '' ? (row.email + ';') : ''}
                            {row.location !== 'lead' ? (row.location + ';\n') : 'Online;\n'}

                            {row.offer !== '' ? (row.offer + ';') : ''}
                          </TableCell>
                          <TableCell>{row.Description}</TableCell>
                          <TableCell>{row.last_change}</TableCell>
                          <TableCell>{row.operation_status === 'CANCEL' ? row.last_change : null}</TableCell>
                          <TableCell>{row.operation_status === 'CANCEL' ? moment(row.write_date).format(formatstr) : null}</TableCell>
                          <TableCell>{
                            this.state.listDelAppointmentId === row.event_id && this.state.listDelAppointment
                              ? <TextField
                                error={this.state.delDescriptionErrorTips ? true : false}
                                helperText={this.state.delDescriptionErrorTips}
                                ref="listDelDescription"
                                id="listDelDescription"
                                label={t('FlexCalendar:label.cancelReason')}
                                variant="outlined"
                                color="secondary"
                                style={{ margin: "1ch" }}
                                value={this.state.listDelAppointmentDep}
                                onChange={(e) => { this.setState({ listDelAppointmentDep: e.target.value }) }}
                                onKeyUp={this.onDelDescriptionKeyUp}
                                onBlur={this.onDelDescriptionBlur}
                              />
                              : (row.operation_status === 'CANCEL' ? row.del_reason : null)}</TableCell>
                          <TableCell>
                            {(authUser.lang === 'en_US') ? moment(row.create_date).format(formatstr) : moment(row.create_date).format(formatstr)}
                          </TableCell>
                          <TableCell>
                            {
                              authUser.username === 'admin' ?
                                ((nowdate < row.appointment_time) && (row.operation_status !== 'CANCEL') && (
                                  <span onClick={() => this.OnDelAppointment(row.event_id)}><i className={"material-icons"}>delete</i></span>
                                ))
                                :
                                ((nowdate < row.appointment_time) && (row.operation_status !== 'CANCEL') && (
                                  <span onClick={() => this.OnDelAppointment(row.event_id)}><i className={"material-icons"}>delete</i></span>
                                ))
                            }
                            &nbsp;
                          </TableCell>
                        </TableRow>
                      );
                    }) : <TableRow><TableCell>{t('label.noAppointmentData')}</TableCell></TableRow>}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        labelRowsPerPage={t('FlexCalendar:label.rowsPerpage')}
                        labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t('FlexCalendar:label.of')} ${count}`}
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        colSpan={5}
                        count={this.state.appointmentRowsCount}
                        rowsPerPage={this.state.rows_perpage_options}
                        page={this.state.rowsPerPage}
                        onPageChange={this.onPageChange}
                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                        SelectProps={{
                          inputProps: { 'aria-label': 'rows per page' },
                          native: true,
                        }}
                      // ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </Paper>
          </> : null}
        {this.state.tabs_value == 2 ?
          <>
            <Paper style={{ padding: "8px", marginTop: "5px" }}>
              <Typography variant="h6">
                {t('FlexCalendar:label.GDPR')}
              </Typography>
              <TextField
                error={this.state.emailGdprErrorTips ? true : false}
                helperText={this.state.emailGdprErrorTips}
                id="emailGdpr"
                label={t('FlexCalendar:Customer Email')}
                variant="outlined"
                color="secondary"
                style={{ margin: "1ch", width: '30%' }}
                value={this.state.gdprEmail}
                onChange={this.onGdprEmailchange}
              />
              <TextField
                error={this.state.customerRrefGdprErrorTips ? true : false}
                helperText={this.state.customerRrefGdprErrorTips}
                id="customerRefGdpr"
                label={t('FlexCalendar:Customer Ref')}
                variant="outlined"
                color="secondary"
                style={{ margin: "1ch", width: '30%' }}
                value={this.state.gdprCustomerRef}
                onChange={this.onGdprRefOrderchange}
              />
              <TextField
                error={this.state.orderNumberGdprErrorTips ? true : false}
                helperText={this.state.orderNumberGdprErrorTips}
                id="orderGdpr"
                label={t('FlexCalendar:Order Number')}
                variant="outlined"
                color="secondary"
                style={{ margin: "1ch", width: '30%' }}
                value={this.state.gdprOrderId}
                onChange={this.onGdprRefOrderchange}
              />
              <br />
              <div className={'phoneGdpr'}>
                <PhoneInput
                  country={getCurrentCountry()}
                  onlyCountries={['at', 'de', 'gb', 'ch', 'li', 'pl', 'us', 'it', 'es', 'ch']}
                  style={{ margin: "1ch", width: '30%' }}
                  id="phoneGdpr"
                  label={t('FlexCalendar:Phone/Mobile')}
                  variant="outlined"
                  color="secondary"
                  value={this.state.gdprphone}
                  onChange={(value, data, event, formattedValue) => {
                    let phone = formattedValue.replace(/\(|\)|-|\s/g, '')
                    this.setState({
                      gdprphone: (phone == '' || phone.length <= 4) ? '' : phone,
                      gdprCustomerRef: '',
                      gdprOrderId: '',
                      gdpr: {
                        ...this.state.gdpr,
                        addToDoNotCallList: (phone == '' || phone.length <= 4) ? false : true,
                        unsubscribeFromSms: (phone == '' || phone.length <= 4) ? false : true,
                        unsubscribe_whatsapp: (phone == '' || phone.length <= 4) ? false : true,
                      }
                    })
                  }}
                >
                </PhoneInput>
              </div>
              <br />
              <FormControlLabel
                style={{ marginLeft: "1%", paddingTop: '1%' }}
                control={<Checkbox
                  checked={this.state.gdpr.unsubscribeFromNewsletter}
                  color="default" name="onlygdpr" />}
                onChange={(e) => {
                  this.setState({
                    gdpr: {
                      ...this.state.gdpr,
                      unsubscribeFromNewsletter: e.target.checked
                    }
                  })
                }}
                label={t('FlexCalendar:button.unsubscribeFromNewsletter')}
              />
              <FormControlLabel
                style={{ marginLeft: "1%", paddingTop: '1%' }}
                control={<Checkbox
                  checked={this.state.gdpr.addToDoNotCallList}
                  onChange={(e) => {
                    this.setState({
                      gdpr: {
                        ...this.state.gdpr,
                        addToDoNotCallList: e.target.checked
                      }
                    })
                  }} color="default" name="onlygdpr" />}
                label={t('FlexCalendar:button.addToDoNotCallList')}
              />
              <FormControlLabel
                style={{ marginLeft: "1%", paddingTop: '1%' }}
                control={<Checkbox
                  checked={this.state.gdpr.deleteCustomerDetails}
                  onChange={(e) => {
                    this.setState({
                      gdpr: {
                        ...this.state.gdpr,
                        deleteCustomerDetails: e.target.checked
                      }
                    })
                  }} color="default" name="onlygdpr" />}
                label={t('FlexCalendar:button.deleteCustomerDetails')}
              />
              <FormControlLabel
                style={{ marginLeft: "1%", paddingTop: '1%' }}
                control={<Checkbox
                  checked={this.state.gdpr.unsubscribeFromAll}
                  onChange={(e) => {
                    this.setState({
                      gdpr: {
                        ...this.state.gdpr,
                        unsubscribeFromAll: e.target.checked
                      }
                    })
                  }} color="default" name="onlygdpr" />}
                label={t('FlexCalendar:button.unsubscribeFromAll')}
              />
              <FormControlLabel
                style={{ marginLeft: "1%", paddingTop: '1%' }}
                control={<Checkbox
                  checked={this.state.gdpr.unsubscribe_points_news}
                  onChange={(e) => {
                    this.setState({
                      gdpr: {
                        ...this.state.gdpr,
                        unsubscribe_points_news: e.target.checked
                      }
                    })
                  }} color="default" name="onlygdpr" />}
                label={t('FlexCalendar:button.unsubscribePointsNews')}
              />
              <FormControlLabel
                style={{ marginLeft: "1%", paddingTop: '1%' }}
                control={<Checkbox
                  checked={this.state.gdpr.unsubscribeFromLetter}
                  onChange={(e) => {
                    this.setState({
                      gdpr: {
                        ...this.state.gdpr,
                        unsubscribeFromLetter: e.target.checked
                      }
                    })
                  }} color="default" name="onlygdpr" />}
                label={t('FlexCalendar:button.unsubscribeFromLetter')}
              />
              <FormControlLabel
                style={{ marginLeft: "1%", paddingTop: '1%' }}
                control={<Checkbox
                  checked={this.state.gdpr.unsubscribeFromSms}
                  onChange={(e) => {
                    this.setState({
                      gdpr: {
                        ...this.state.gdpr,
                        unsubscribeFromSms: e.target.checked
                      }
                    })
                  }} color="default" name="onlygdpr" />}
                label={t('FlexCalendar:button.unsubscribeFromSms')}
              />
              <FormControlLabel
                style={{ marginLeft: "1%", paddingTop: '1%' }}
                control={<Checkbox
                  checked={this.state.gdpr.unsubscribe_whatsapp}
                  onChange={(e) => {
                    this.setState({
                      gdpr: {
                        ...this.state.gdpr,
                        unsubscribe_whatsapp: e.target.checked
                      }
                    })
                  }} color="default" name="onlygdpr" />}
                label={t('FlexCalendar:button.unsubscribeWhatsApp')}
              />
              <br />
              <Button onClick={this.submissionGdpr} style={{ margin: "2ch 1ch" }} variant="contained" color="primary">
                {t('FlexCalendar:button.gdprSubmission')}
              </Button>
            </Paper>
          </> : null}
      </React.Fragment>
    );
  }
};


export default CalendarArea;


